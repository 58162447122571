import React, { useState, useEffect } from 'react';
import Publicaciones from './Publicacionesphp';
import Mesas from './Mesasphp';
import HorariosCursos from './HorariosCursos';
import Normativasphp from './Normativasphp';

const Panel = () => {

    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const tokenGuardado = localStorage.getItem("token");
        

        if (tokenGuardado !== null) {
            setToken(tokenGuardado);
        }


        setLoading(false); // Indica que la carga ha terminado
    }, []);


    useEffect(() => {
        if (!loading) {
            if (token === null) {
                // Si no se encuentra ningún token, redirige a la página de inicio
                window.location.href = '/';
            }
        }
    }, [loading, token]);

    const cargarSeccionPublicaciones = () => {
        setSeccion('publicaciones');
    }

    const cargarSeccionMesas = () => {
        setSeccion('mesas');
    }
    
    const cargarSeccionN = () =>{
        setSeccion ('normativas')
    }


    const cerrarSesion = () => {
        // Lógica para cerrar la sesión y eliminar el token
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    const [seccion, setSeccion] = useState('');

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div className='seccion_menu'>
            <div className='seccion'>
                <h1>Panel Administrador</h1>
                <div className='btn' onClick={cargarSeccionPublicaciones}>
                    Publicaciones
                </div>
                <div className='btn' onClick={cargarSeccionMesas}>
                    Mesas
                </div>                
                <div className='btn' onClick={cargarSeccionN}>
                    Normativas
                </div>
                <div className='btn' onClick={cerrarSesion}>
                    Cerrar Sesión
                </div>
            </div>
            {seccion === 'publicaciones' && <Publicaciones />}
            {seccion === 'mesas' && <Mesas />}
            {seccion === 'normativas' && <Normativasphp/>}
        </div>
    );
}

export default Panel;
