import axios from 'axios'
import React, { useEffect, useState } from 'react'

const normativas = 'https://www.cpem41.edu.ar/backend.php/normativas.php'

const backendDomain = "../backend.php/";

const Normativas = () => {
    const [informacion, setInformacion] = useState([])

    // Corrección aquí: encapsula getInformacion en una función
    useEffect(() => {
        getInformacion()
    }, [])

    const getInformacion = async () => {
        try {
            const response = await axios.get(normativas)
            if (response && response.data) {
                const reversedData = response.data.slice().reverse()
                setInformacion(reversedData)
            } else {
                console.error('La respuesta no contiene datos:', response)
            }
        } catch (error) {
            console.error('Error al obtener la información:', error)
        }
    }

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`https://www.cpem41.edu.ar/backend.php/normativasborrar.php?id=${id}`)
            if (response.data && response.data.message) {
                console.log(response.data.message)
                // Volver a obtener la información después de eliminar
                getInformacion()
            } else {
                console.error('Error al eliminar el registro:', response)
            }
        } catch (error) {
            console.error('Error al realizar la eliminación:', error)
        }
    }

    return (
        <div className='seccion'>
        <div className="contenedor-tablas">

            <table className="mi-tabla">
                    <caption> Normativas </caption>
                    
                        {informacion && informacion.length > 0 && informacion.map((item) => (
                            <tr key={item.id}>
                                <td>{item.nombre}</td>
                                <td>{item.descripcion}</td>
                                <td>
                                    <a href={backendDomain + item.url} target="_blank" rel="noopener noreferrer" className="linksD"><button>Ver PDF</button></a>
                                </td>
                            </tr>
                        ))}
                </table>
            </div>
        </div>
    )
}

export default Normativas
