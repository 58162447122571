import React, { useState } from 'react';

import NoticiasDocentes from '../Docentes/Noticia Docentes';
import Formularios from '../Docentes/Formularios';
import HorariosCursos from '../Usuariosphp/HorariosCursos';
import Normativas from '../Docentes/Normativas';

const Docentes = () => {
    const [seccion, setSeccion] = useState('')
    
    const [codigo, setCodigo] = useState()

    const cargarSeccionF = () =>{
        setSeccion ('formularios')
    }

    const cargarSeccionN = () => {
        setSeccion ('newdocentes')
    }

    const cargarSeccionH = () => {
        setSeccion ('login')
    }

    const cargarSeccionC = () =>{
        setSeccion ('Normativas')
    }

    const login = () =>{
        if (codigo === 'cpem041'){
            setSeccion ('horarios')
        } else {
            alert('Usuario no encontrado..')
        }

    }

    return (
        <div className="seccion_menu">
            <div className="seccion">
                <h2>DOCENTES</h2>

                <div className="btn" onClick={cargarSeccionN}>
                    Nuevos Docentes
                </div>
                <div className="btn" onClick={cargarSeccionF}>
                    Formularios
                </div>
                <div className='btn' onClick={cargarSeccionC}>
                    Normativas
                </div>
                <div className='btn' onClick={cargarSeccionH}>
                    Horarios de cursos
                </div>
                
            </div>

            {seccion === 'formularios' && <Formularios/>}
            {seccion === 'newdocentes' && <NoticiasDocentes />}
            {seccion === 'Normativas' && <Normativas/>}
            
            {seccion === 'horarios' ? 
            (<HorariosCursos />
            ) 
            : seccion === 'login' ?
            (<div className="seccion">
                <div className="modal-content">
                    <h4>Ingrese codigo de acceso:</h4>
                    <input
                        type="password"
                        placeholder="Codigo"
                        value={codigo}
                        onChange={(e) => setCodigo(e.target.value)}
                    />
                    <button onClick={login}>Enviar</button>
                    <button onClick={() => setSeccion('')}>Cancelar</button>
                </div>
            </div>)
            :
            (null)            
            }

        </div>
    );
}

export default Docentes;
