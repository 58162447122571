import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const baseURL = 'https://www.cpem41.edu.ar/backend.php/normativassubir.php';
const normativas = 'https://www.cpem41.edu.ar/backend.php/normativas.php';
const baseBorrar = 'https://www.cpem41.edu.ar/backend.php/normativasborrar.php'

const backendDomain = "../backend.php/";


const Normativasphp = () => {
  const [seccion, setSeccion] = useState("");
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [archivo, setArchivo] = useState(null); // Cambiar el estado para manejar el archivo

  
  const [informacion, setInformacion] = useState([])
    

const getInformacion = async () => {
    try {
        const response = await axios.get(normativas);
        if (response && response.data) {
            const reversedData = response.data.slice().reverse();
            setInformacion(reversedData);
        } else {
            console.error('La respuesta no contiene datos:', response);
        }
    } catch (error) {
        console.error('Error al obtener la información:', error);
    }
};

  const subirpost = () => {
    setSeccion('subirpost');
  };

  const admpost = () => {
    getInformacion ()
    setSeccion('admpost')

  };
    
  const eliminarRegistro = async (id) => {
    const confirmacion = await Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Eliminar el registro de forma permanente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
    });

    if (confirmacion.isConfirmed) {
        try {
            const response = await axios.delete(`${baseBorrar}?id=${id}`);
            console.log(response); // Log para verificar la respuesta
            Swal.fire({
                title: '¡Éxito!',
                text: 'El registro se ha eliminado correctamente.',
                icon: 'success',
            });

            getInformacion();
        } catch (error) {
            console.error('Error al eliminar el registro:', error.response || error); // Muestra el error detallado
            Swal.fire({
                title: 'Error',
                text: `Hubo un problema al intentar eliminar el registro: ${error.message || 'Error desconocido'}`,
                icon: 'error',
            });
        }
    }
};


  const guardadoI = async (e) => {
    e.preventDefault();

    if (!titulo) {
      Swal.fire({
        title: 'Error',
        text: 'El título es obligatorio',
        icon: 'error',
      });
      return;
    }

    if (!archivo) {
      Swal.fire({
        title: 'Error',
        text: 'Debe subir un archivo PDF',
        icon: 'error',
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('titulo', titulo);
      formData.append('descripcion', descripcion);
      formData.append('archivo', archivo); // Aquí agregamos el archivo

      const response = await axios.post(baseURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Limpiar los campos del formulario si la solicitud es exitosa
      setTitulo('');
      setDescripcion('');
      setArchivo(null);

      Swal.fire({
        title: '¡Éxito!',
        text: 'El registro se ha añadido correctamente.',
        icon: 'success',
      });
    } catch (error) {
      console.error('Error al agregar el post:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al intentar insertar el registro.',
        icon: 'error',
      });
    }
  };

  return (
    <div className="seccion">
      <div className="btn" onClick={subirpost}>
        Subir Normativa
      </div>
      <div className="btn" onClick={admpost}>
        Administrar Normativa
      </div>

      {seccion === 'subirpost' && (
        <div className="bodylogin2">
          <div className="centrado">
            <h1>Crear Publicación</h1>

            <form onSubmit={guardadoI}>
              <label htmlFor="titulo">Título / obligatorio</label>
              <div className="boxinput">
                <input
                  type="text"
                  id="titulo"
                  className="controls"
                  placeholder="Titulo / obligatorio"
                  name="titulo"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                />
              </div>

              <label htmlFor="descripcion">Descripción</label>
              <div className="boxinput">
                <textarea
                  id="descripcion"
                  className="controls"
                  placeholder="Descripción / opcional"
                  name="descripcion"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </div>

              <label htmlFor="archivo">Subir PDF</label>
              <div className="boxinput">
                <input
                  type="file"
                  id="archivo"
                  className="controls"
                  accept="application/pdf"
                  onChange={(e) => setArchivo(e.target.files[0])} // Manejo del archivo
                />
              </div>

              <div className="boxinput">
                <button type="submit">Agregar Post</button>
              </div>
            </form>
          </div>
        </div>
      )}

{seccion === 'admpost' && (
            <div>
                <h1>Borrado de Normativa</h1>            

                <div className='contenedor-tablas'>
                    <table className='modificarPost'>
                        <thead>
                            <tr>
                                <th>Título</th>
                                <th>Descripción</th>
                                <th>PDF</th>
                                <th>Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {informacion && informacion.length > 0 && informacion.map((item, index) => (
                                <tr key={item.id || index}>
                                    <td>{item.nombre}</td>
                                    <td>{item.descripcion}</td>
                                    <td>
                                      <a href={backendDomain + item.url} target="_blank" rel="noopener noreferrer"className="linksD"><button>Ver PDF</button></a>
                                    </td>
                                    <td>
                                        <button onClick={() => eliminarRegistro(item.id)}>Borrar</button> 
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )}
  
        </div>
      
      )}


export default Normativasphp;
